
@import '~bootstrap/dist/css/bootstrap.min.css';

html {
    position: relative;
    min-height: 100%;
    overflow-y: scroll;
}

/*body {
    margin-bottom: 60px;
}*/

.is-invalid {
    background-image: none !important;
    /*style to make */
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-control.is-invalid, .was-validated .form-control:invalid {
    border-color: #dc3545 !important;
    padding-right: unset !important;
    background-image: none !important;
    background-position: unset !important;
    background-size: unset !important;

}

.w50 {
    min-width: 50px;
    width: 50px;
}

.w62 {
    min-width: 62px;
    width: 62px;
}

.w74 {
    min-width: 74px !important;
    width: 74px !important;
}

.w86 {
    min-width: 86px;
    width: 86px;
}

.w100 {
    min-width: 100px !important;
    width: 100px !important;
}

.w125 {
    min-width: 125px;
    width: 125px;
}

.w150 {
    min-width: 150px;
    width: 150px;
}

.w200 {
    min-width: 200px;
    width: 200px;
}

.w250 {
    min-width: 250px;
    width: 250px;
}

.w300 {
    min-width: 300px;
    width: 300px;
}

.w350 {
    min-width: 350px;
    width: 350px;
}

.w375 {
    min-width: 375px;
    width: 375px;
}

.w400 {
    min-width: 400px;
    width: 400px;
}

.w475 {
    min-width: 475px;
    width: 475px;
}

.belowLabel {
    /*text-decoration: underline;*/
    color: #007bff;
}

/*Active inputs or textarea are changing bg-color to Yellow on FOCUS*/
input:focus {
    background-color: #FFFF00 !important;
}

/* Hide increment-decrement arrows in Chrome */
input[type=number] {
    -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <-- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

textarea:focus {
    background-color: #FFFF00 !important;
}

.btn-light:focus {
    color: #111;
    background-color: #e2e6ea;
    border-color: #1d2124;
    box-shadow: 0 0 0 3px rgba(52, 58, 64, 0.5);
}

.btn-light:hover {
    color: #111;
    background-color: #e2e6ea;
    border-color: #1d2124;
    box-shadow: 0 0 0 3px rgba(52, 58, 64, 0.5);
}

.btn-light:disabled {
    color: #111;
    background-color: #e2e6ea;
    border-color: #1d2124;
    box-shadow: none;
}

.bg-dark-grey {
    background-color: #e2e6ea !important;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link {
    border-bottom-color: #495057;
}

    .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
        border-top-color: #495057;
        border-right-color: #495057;
        border-left-color: #495057;
        border-bottom-color: #f8f9fa;
    }

    .nav-tabs.nav-link:hover {
        background-color: greenyellow;
        border-bottom-color: red;
    }


/*if scroll is needed simple solution and not good enough */
/*.nav-tabs {
    overflow-x: auto;
    overflow-y: hidden;
    display: -webkit-box;
    display: -moz-box;
}*/

/*Added margine to the cards so that border is visiable properly */
.margin-border {
    margin: 0.998px;
}

.form-group {
    margin-bottom: 8px !important;
}

.no-pointer {
    cursor: text !important;
}
/*Styling for a font awesome sorting icons*/

.sorting-icon-size {
    font-size: 20px
}

.nounderline {
    text-decoration: none !important
}

/*
    Added style below to handle same height of each typeahead/multiselect options(regardles of the option content)
    Setting only first element as 32px, in case that other options have more then 1 row of texts...
*/
button.dropdown-item:first-of-type {
    height: 32px !important;
}

ngb-modal-backdrop {
    z-index:0!important;
}
